const FALLBACK_API_URL = 'https://aorten-gefaesszentrum.ch'

const getUrl = () => {
  return process.env.GATSBY_API_URL ? process.env.GATSBY_API_URL : FALLBACK_API_URL
}
export const URL = getUrl()

const getMapsApiKey = () => {
  return process.env.GATSBY_MAPS_API_KEY;
}
export const MAPS_API_KEY = getMapsApiKey();


export function initMap() {
  const lat = 47.351437;
  const lng = 8.576569;
  const map = new window.google.maps.Map(document.getElementById('map'), {
    center: { lat, lng },
    zoom: 16,
    gestureHandling: 'greedy'
  });

  const marker = new window.google.maps.Marker({
    position: { lat, lng },
    url: 'https://goo.gl/maps/jAjd2CYAeWy',
    map: map
  });

  marker.addListener('click', function () {
    window.open(marker.url, '_blank');
  });
}

export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  return match ? match[2] : null
}
