import React, { useState } from 'react'
import styled from 'styled-components'
import loopIcon from '../images/loop-icon.svg'
import { navigate } from "@reach/router" 


const SearchInput = styled.div`
  border: none;
  font-size: 14px;
  align-self: baseline;

  input {
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
    padding: 4px 0;
    outline: none;
    width: 120px;

    @media(max-width: 1380px) {
      font-size: 13px;
    }
  
    @media(max-width: 1300px) {
      font-size: 12px;
    }

    ::placeholder {
      color: #fff;
    }
  }
`;

const LoopImage = styled.img`
  vertical-align: middle;
`;

const NavbarSearch = props => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleSearchInputChange = e => {
      setSearchFilter(e.target.value)
      sessionStorage.setItem('search', e.target.value)
    }

    const handleSearch = () => {
      if (window.location.pathname.startsWith('/wiki')) {
        window.location.reload(false)
      } else {
        navigate("/wiki")
      }
    }

    return <SearchInput>
        <LoopImage src={loopIcon} />
        <input
            type="search"
            placeholder="Suchen..."
            value={searchFilter}
            onChange={handleSearchInputChange} 
            onKeyPress={e => e.key === 'Enter' && searchFilter.length > 0 && handleSearch()}
        />
    </SearchInput>
}

export default NavbarSearch
