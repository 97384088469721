import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// import facebookIcon from '../images/facebook-icon.svg';
// import linkedinIcon from '../images/linkedin-icon.svg';
import impressumFile from '../files/impressum.pdf'


const StyledWrapper = styled.div`
  height: 225px;
  width: 100%;
  background-color: #0c1850;
  padding: 60px 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
  // z-index: 1;

  @media(min-width: 2000px) {
    padding: 60px 18%;
  }

  @media(max-width: 1184px) {
    height: auto;
    padding: 25px 30px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media(max-width: 1184px) {
    flex-flow: column;
    align-items: flex-start;
  }
`;

const Title = styled.p`
  line-height: initial;

  @media(max-width: 1184px) {
    color: #fff;
    font-size: 14px;
  }
`;

const Nav = styled.nav`
  @media(max-width: 1184px) {
    margin: 30px 0;
    width: 100%;
  }
`;

const Ul = styled.ul`
  display: flex;

  @media(max-width: 1184px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
`;

const Li = styled.li`
  margin: 0 20px;
  cursor: pointer;

  @media(max-width: 1184px) {
    margin: 0;
    font-size: 14px;
    
    a {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const SocialMedia = styled.div`
  width: 100px;
`;

const P = styled.p`
  text-align: center;
  font-size: 13px;

  @media(max-width: 1184px) {
    text-align: left;
  }
`;

const footer = () => {
  return (
    <StyledWrapper>
      <FirstRow>
        <Link to="/">
          <Title>Aorten- GefässZentrum</Title>
        </Link>
        <Nav>
          <Ul>
            <Li>
              <Link
                to='/'>
                Aorten- & GefässZentrum
              </Link>
            </Li>
            <Li>
              <Link
                to='/dr-varga'>
                Dr. Szente Varga
              </Link>
            </Li>
            <Li>
              <Link
                to='/dr-lachat'>
                Prof. Dr. Lachat
              </Link>
            </Li>
            <Li>
              <Link
                to='/wiki'>
                Gefäss-Wiki
              </Link>
            </Li>
            <Li>
              <Link
                to='/aktuelles'>
                Aktuelles
              </Link>
            </Li>
            <Li>
              <a href={impressumFile} className="impressum file" title="impressum file">
                Impressum
              </a>
            </Li>
          </Ul>
        </Nav>
        <SocialMedia>
          {/*<a href="#facebook">*/}
            {/*<Img src={facebookIcon} alt="facebook" />*/}
          {/*</a>*/}
          {/*<a href="#linkedin">*/}
            {/*<Img src={linkedinIcon} alt="facebook" />*/}
          {/*</a>*/}
        </SocialMedia>
      </FirstRow>
      <P>Copyright © {new Date().getFullYear()} Dr. med. Michael Szente Varga. All Rights Reserved.</P>
    </StyledWrapper>
  )
};

export default footer;
