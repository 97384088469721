import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import cookieImage from '../images/cookie-image.svg'
import datenschutzerklarungFile from '../files/datenschutzerklarung.pdf'
import { getCookie } from '../utils/utils'


const Wrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  background: #0a174b;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;

  @media(max-width: 1184px) {
  }
`;

const PopupContent = styled.div`
  position: relative;
  width: 90%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin: 0 auto;
  padding: 24px 18px 4px;

  @media(min-width: 1920px) {
    width: 70%;
  }

  @media(min-width: 2000px) {
    width: 60%;
  }

  @media(max-width: 1184px) {
    width: 100%;
    display: block;
    padding: 24px 18px;
  }

  p {
    line-height: 1.4;
    margin-bottom: 20px;
    color: #fff;

    @media(max-width: 1184px) {
      margin-bottom: 10px;
    }
  }
`

const CookieImage = styled.img`
  flex: 0 0 auto;
  margin-right: 50px;
  margin-bottom: 20px;

  @media(max-width: 1184px) {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;

  @media(max-width: 1184px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`

const CloseButton = styled.div`
  color: #12237f;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  user-select: none;
  height: fit-content;
  padding: 12px 16px;
  border-radius: 3px;
  text-align: center;
  margin-left: 50px;

  @media(max-width: 1184px) {
    margin-left: 0;
    margin-top: 15px;
    font-size: 12px;
    padding: 8px 16px;
  }
`

const NavigationLink = styled.a`
  color: #fff;
  font-weight: 600;
`


const CookiesPopup = (props) => {

  if (typeof window === 'undefined') {
    global.window = {}
  }

  if (typeof document === 'undefined') {
    global.document = {}
  }
  
  const [isCookiesPopupHidden, setIsCookiesPopupHidden] = useState('true')

  useEffect(() => {
    if (!document) return null
    setIsCookiesPopupHidden(getCookie('cookiesPopupHidden') === 'true')
  }, [document])

  if (isCookiesPopupHidden) return null

  return (
    <Wrapper>
     <PopupContent>
        <CookieImage src={cookieImage} alt="cookie" />
        <div>
          <Title>Im OP haben Cookies nichts verloren.</Title>
          <p>Auf unserer Website helfen sie jedoch, diese nutzerfreundliche zu gestalten, sie fortlaufend zu verbessern und die Zugriffe auf unsere Website zu analysieren. Mehr dazu finden Sie in unserer <NavigationLink href={datenschutzerklarungFile} className="datenschutzerklarung file" title="datenschutzerklarung file">Datenschutzrichtlinie</NavigationLink>.</p>
        </div>
        <CloseButton onClick={() => {
            document.cookie = `cookiesPopupHidden=true; path=/`
            setIsCookiesPopupHidden(true)
          }}>
            Einverstanden
        </CloseButton>
      </PopupContent> 
    </Wrapper>
  )
}

export default CookiesPopup