import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ResponsiveHeader from '../components/responsiveHeader';
import menuIcon from '../images/group.svg';
import CookiesPopup from './CookiesPopup';
import NavbarSearch from './NavbarSearch';


const StyledWrapper = styled.header`
  width: 100%;
  height: 64px;
  background-color: #0b164a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  @media(min-width: 2000px) {
    padding: 0 18%;
  }
`;

const Title = styled.p`
  line-height: initial;
`;

const Nav = styled.nav`
  display: none;

  @media(min-width: 1185px) {
    display: block;
  }
`;

const Ul = styled.ul`
  display: flex;
`;

const Li = styled.li`
  position: relative;
  margin: 0 16px;
  color: #fff;

  @media(max-width: 1380px) {
    font-size: 13px;
  }

  @media(max-width: 1300px) {
    font-size: 12px;
    margin: 0 8px;
  }

  :after {
    position: absolute;
    top: 30px;
    left: 50%;
    width: 6px;
    height: 6px;
    margin-left: -3px;
    content: "";
    border-radius: 50%;
    background-color: #ff413d;
    opacity: ${props => props.checked ? '1' : '0'};
    transition: all 0.4s;
  }
`;

const MenuBurgerIcon = styled.img`
  display: none;
  cursor: pointer;

  @media(max-width: 1184px) {
    display: flex;
  }
`;

const SearchWrapper = styled.div`

`;

class Header extends Component {
  state = {
    currentScrollPosition: null,
    currentSection: '',
    allSections: null,
    offset: 180,
    homeSectionHeight: null,
    isResposiveHeaderVisible: false
  }

  componentDidMount() {
    const parallaxImages = document.querySelectorAll('.parallax');
    const arrayParallaxImages = Array.prototype.slice.call(parallaxImages);

    window.addEventListener("scroll", () => {
      this.setState({ currentScrollPosition: window.pageYOffset });
      arrayParallaxImages.forEach(image => {
        image.style.transform = `translate3d(0px, ${-this.state.currentScrollPosition / 2}px, 0`;
      })
    })

    window.addEventListener("resize", () => {
      this.getAllSections();
    })

    let selectedTab = null
    if (window.location.pathname.startsWith('/dr-varga')) {
      selectedTab = 'dr-varga'
    }
    else if (window.location.pathname.startsWith('/dr-lachat')) {
      selectedTab = 'dr-lachat'
    }
    else if (window.location.pathname.startsWith('/wiki')) {
      selectedTab = 'wiki'
    }
    else if (window.location.pathname.startsWith('/aktuelles') || window.location.pathname.startsWith('/aktuell')) {
      selectedTab = 'aktuelles'
    }
    else {
      selectedTab = 'clinic'
    }
    this.setState({
      currentSection: selectedTab
    })

    this.getAllSections();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
  }

  getAllSections = () => {
    const sections = document.querySelectorAll('.scrollSection');
    const allSections = Array.prototype.slice.call(sections);
    this.setState({ allSections });
  }

  toggleResponsiveHeader = () => {
    this.setState(prevState => ({
      isResposiveHeaderVisible: !prevState.isResposiveHeaderVisible
    }));
  }

  render() {
    return (
      <>
        <StyledWrapper>
          <Link to="/">
            <Title>Aorten- GefässZentrum</Title>
          </Link>
          <Nav>
            <Ul>
              <Li
                checked={'clinic' === this.state.currentSection}>
                <Link
                  onClick={() => this.setState({ currentSection: 'clinic' })}
                  to='/'>
                  Aorten- & GefässZentrum
                </Link>
              </Li>
              <Li
                checked={'dr-varga' === this.state.currentSection}>
                <Link
                  onClick={() => this.setState({ currentSection: 'dr-varga' })}
                  to='/dr-varga'>
                  Dr. Szente Varga
                </Link>
              </Li>
              <Li
                checked={'dr-lachat' === this.state.currentSection}>
                <Link
                  onClick={() => this.setState({ currentSection: 'dr-lachat' })}
                  to='/dr-lachat'>
                  Prof. Dr. Lachat
                </Link>
              </Li>
              <Li
                checked={'wiki' === this.state.currentSection}>
                <Link
                  onClick={() => this.setState({ currentSection: 'wiki' })}
                  to='/wiki'>
                  Gefäss-Wiki
                </Link>
              </Li>
              <Li
                checked={'aktuelles' === this.state.currentSection}>
                <Link
                  onClick={() => this.setState({ currentSection: 'aktuelles' })}
                  to='/aktuelles'>
                  Aktuelles
                </Link>
              </Li>
              <Li>
                <SearchWrapper>
                  <NavbarSearch />
                </SearchWrapper>
              </Li>
            </Ul>
          </Nav>
          <MenuBurgerIcon
              src={menuIcon}
              alt="menu"
              onClick={this.toggleResponsiveHeader} />
            <ResponsiveHeader 
              isOpened={this.state.isResposiveHeaderVisible}
              currentSection={this.state.currentSection}
              onClick={this.toggleResponsiveHeader}
            />
        </StyledWrapper>

        <CookiesPopup />
      </>
    )
  }
}

export default Header
