import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import posed from 'react-pose';
import NavbarSearch from './NavbarSearch';

const StyledWrapper = styled.div`
  background-color: #091138;
  width: 100vw;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 3;
  padding: 28px 0;
  display: none;
`;

const StyledWrapperAnimate = posed(StyledWrapper)({
  visible: {
    applyAtStart: { display: 'block' },
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
  }
});

const Ul = styled.ul`
  display: flex;
  flex-flow: column;
`;

const Li = styled.li`
  text-align: center;
  padding: 10px 0;
  margin: 0 auto;
  color: #fff;
  cursor: pointer;
  width: 100%;
  background-color: ${props => props.checked ? '#0b164a' : 'transparent'};
`;

const SearchWrapper = styled.div`
  cursor: default;
`;

const responsiveHeader = (props) => (
  <StyledWrapperAnimate pose={props.isOpened ? 'visible' : 'hidden'}>
    <Ul>
      <Li checked={'clinic' === props.currentSection}>
        <Link
          onClick={props.onClick} 
          to='/'>
          Aorten- & GefässZentrum
        </Link>
      </Li>
      <Li checked={'dr-varga' === props.currentSection}>
        <Link
          onClick={props.onClick} 
          to='/dr-varga'>
          Dr. Szente Varga
        </Link>
      </Li>
      <Li checked={'dr-lachat' === props.currentSection}>
        <Link
          onClick={props.onClick} 
          to='/dr-lachat'>
          Prof. Dr. Lachat
        </Link>
      </Li>
      <Li checked={'wiki' === props.currentSection}>
        <Link
          onClick={props.onClick} 
          to='/wiki'>
          Gefäss-Wiki
        </Link>
      </Li>
      <Li checked={'aktuelles' === props.currentSection}>
        <Link
          onClick={props.onClick} 
          to='/aktuelles'>
          Aktuelles
        </Link>
      </Li>
      <Li>
        <SearchWrapper>
          <NavbarSearch />
        </SearchWrapper>
      </Li>
    </Ul>
  </StyledWrapperAnimate>
)

export default responsiveHeader;